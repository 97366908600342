

































import { Component, Vue, Prop } from "vue-property-decorator";
import { DeoEvent } from "@/domain";
import marked from "marked";
import {
  ParticipantsList,
  ParticipantsCount,
} from "@/components/participations";

@Component({
  components: {
    ParticipantsList,
    ParticipantsCount,
  },
})
export default class EventCard extends Vue {
  @Prop({ required: true, type: Object })
  event!: DeoEvent;

  @Prop({ default: false })
  short!: boolean;

  @Prop({ default: "" })
  link!: string;

  get id(): string {
    return this.event.id;
  }

  get title(): string {
    return this.event.title;
  }

  get coverImageURL(): string {
    return this.event.coverImageURL;
  }

  get content(): string {
    return this.event.page.markdown;
  }

  get renderedHTML(): string {
    return marked(this.content);
  }

  get maxParticipants(): number {
    return this.event.maxParticipants;
  }
}
