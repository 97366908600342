





















import { Component, Vue, Prop } from "vue-property-decorator";
import { EventParticipant } from "@/domain";
import ParticipantsListItem from "./ParticipantsListItem.vue";
import { getMyUID } from "@/services";

@Component({
  components: {
    ParticipantsListItem,
  },
})
export default class ParticipantsListItems extends Vue {
  @Prop({ required: true })
  participants!: EventParticipant[];

  @Prop({ required: true, type: Number })
  maxParticipants!: number;

  get leftParticipantsCount(): number {
    return Math.max(this.maxParticipants - this.participants.length, 0);
  }

  get leftParticipants(): number[] {
    return new Array(this.leftParticipantsCount).map((_, i) => i);
  }

  get amIParticipating(): boolean {
    const myUID = getMyUID();
    return this.participants.filter((p) => p.memberUID === myUID).length > 0;
  }
}
