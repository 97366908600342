






import { Component, Vue } from "vue-property-decorator";
import { Resource, StatefulResource } from "vue-stateful-resource";
import { FeedEntry } from "@/domain";
import { fetchFeed } from "@/services";
import FeedList from "./FeedList.vue";

@Component({
  components: {
    StatefulResource,
    FeedList,
  },
})
export default class FeedView extends Vue {
  feedEntriesResource = Resource.empty<FeedEntry[]>();

  get items(): FeedEntry[] {
    if (!this.feedEntriesResource.result) return [];
    return this.feedEntriesResource.result;
  }

  beforeMount(): void {
    this.reloadFeed();
  }

  reloadFeed(): void {
    Resource.fetchResource("feed", fetchFeed, (res) => {
      this.feedEntriesResource = res;
    });
  }
}
