import { mountDeoetorbiWepapp } from "./index";
export * from "./index";

window.mountDeoetorbiWepapp = mountDeoetorbiWepapp;

declare global {
  interface Window {
    mountDeoetorbiWepapp: typeof mountDeoetorbiWepapp;
  }
}
