














import { Component, Vue } from "vue-property-decorator";
import { EnsureLoggedIn, LogInScreen, AppBar } from "./components";

@Component({
  components: {
    EnsureLoggedIn,
    LogInScreen,
    AppBar,
  },
})
export default class App extends Vue {}
