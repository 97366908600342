























import { Component, Vue, Prop } from "vue-property-decorator";
import { EventParticipant } from "@/domain";
import { getMyUID } from "@/services";

@Component
export default class ParticipantsListItem extends Vue {
  @Prop({ default: "" })
  participant!: EventParticipant;

  @Prop({ default: false })
  canParticipate!: boolean;

  @Prop({ required: true })
  no!: number;

  get isMe(): boolean {
    return this.participant.memberUID === getMyUID();
  }

  get displayName(): string {
    return this.participant.participantDisplayName;
  }

  participate(): void {
    this.$emit("participate");
  }

  unparticipate(): void {
    this.$emit("unparticipate");
  }
}
