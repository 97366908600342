



















import { Component, Vue, Prop } from "vue-property-decorator";
import { Resource, StatefulResource } from "vue-stateful-resource";
import { EventParticipant } from "@/domain";
import { fetchParticipants, participate, unparticipate } from "@/services";
import ParticipantsListItems from "./ParticipantsListItems.vue";

@Component({
  components: {
    StatefulResource,
    ParticipantsListItems,
  },
})
export default class ParticipantsList extends Vue {
  participantsResource = Resource.empty<EventParticipant[]>();

  @Prop({ required: true, type: String })
  eventId!: string;

  @Prop({ required: true, type: Number })
  maxParticipants!: number;

  get participants(): EventParticipant[] {
    if (!this.participantsResource.result) return [];
    return this.participantsResource.result;
  }

  beforeMount(): void {
    this.reloadParticipants();
  }

  reloadParticipants(): void {
    Resource.fetchResource(
      `participants-list:${this.eventId}`,
      () => fetchParticipants(this.eventId),
      (res) => {
        this.participantsResource = res;
      }
    );
  }

  askParticipate(): void {
    (this.$refs.participateModal as any).show();
  }

  askUnparticipate(): void {
    (this.$refs.unparticipateModal as any).show();
  }

  participate(): void {
    participate(this.eventId).then(
      () => {
        this.reloadParticipants();
      },
      (err) =>
        this.$bvToast.toast(`Błąd ${err.message}`, {
          title: "Błąd",
          autoHideDelay: 5000,
        })
    );
  }

  unparticipate(): void {
    unparticipate(this.eventId).then(
      () => {
        this.reloadParticipants();
      },
      (err) =>
        this.$bvToast.toast(`Błąd ${err.message}`, {
          title: "Błąd",
          autoHideDelay: 5000,
        })
    );
  }
}
