





import { Component, Vue, Prop } from "vue-property-decorator";
import { FeedEntryMarkdown } from "@/domain";
import marked from "marked";

@Component
export default class FeedEntryMarkdownItem extends Vue {
  @Prop({ required: true })
  markdown!: FeedEntryMarkdown;

  get content(): string {
    return this.markdown.content;
  }

  get role(): "text" | "title" {
    return this.markdown.role || "text";
  }

  get isTitle(): boolean {
    return this.role === 'title';
  }

  get renderedHTML(): string {
    return marked(this.content);
  }
}
