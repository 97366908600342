

















import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";

@Component
export default class EnsureLoggedIn extends Vue {
  get user(): firebase.User | null {
    return firebase.auth().currentUser;
  }

  get displayName(): string {
    return this.user ? this.user.displayName || "(no name)" : "(no user)";
  }

  async logOut(): Promise<void> {
    await firebase.auth().signOut();
  }
}
