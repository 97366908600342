







import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebaseui/dist/firebaseui.css";
import * as firebaseui from "firebaseui";

@Component
export default class LogInScreen extends Vue {
  mounted(): void {
    this.initializeFirebaseUI();
  }
  private initializeFirebaseUI() {
    console.log("Loading auth...");
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const uiConfig = {
      signInSuccessUrl: window.location.href,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
    };
    console.log("Mounting auth...");
    ui.start("#firebaseui-auth-container", uiConfig);
  }
}
