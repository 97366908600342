






import { Component, Vue, Prop } from "vue-property-decorator";
import { DeoEvent } from "@/domain";
import { EventCard } from "@/components/event";

@Component({
  components: {
    EventCard,
  },
})
export default class FeedEntryEventItem extends Vue {
  @Prop({ required: true })
  event!: DeoEvent;

  get linkTo(): string {
    return `/event/${this.event.id}`;
  }
}
