












import { Component, Vue, Prop } from "vue-property-decorator";
import { DeoEvent, FeedEntry, FeedEntryMarkdown } from "@/domain";
import FeedEntryMarkdownItem from "./FeedEntryMarkdownItem.vue";
import FeedEntryEventItem from "./FeedEntryEventItem.vue";

@Component({
  components: {
    FeedEntryMarkdownItem,
    FeedEntryEventItem,
  },
})
export default class FeedEntryAny extends Vue {
  @Prop({ required: true })
  entry!: FeedEntry;

  get isMarkdown(): boolean {
    return !!this.entry.markdown;
  }

  get isEvent(): boolean {
    return !!this.entry.event;
  }

  get markdown(): FeedEntryMarkdown {
    return this.entry.markdown!;
  }

  get event(): DeoEvent {
    return this.entry.event!;
  }
}
