








import { Component, Vue, Prop } from "vue-property-decorator";
import { Resource, StatefulResource } from "vue-stateful-resource";
import { EventParticipant } from "@/domain";
import { fetchParticipants, getMyUID } from "@/services";
import ParticipantsListItems from "./ParticipantsListItems.vue";

@Component({
  components: {
    StatefulResource,
    ParticipantsListItems,
  },
})
export default class ParticipantsCount extends Vue {
  participantsResource = Resource.empty<EventParticipant[]>();

  @Prop({ required: true, type: String })
  eventId!: string;

  @Prop({ required: true, type: Number })
  maxParticipants!: number;

  get participants(): EventParticipant[] {
    if (!this.participantsResource.result) return [];
    return this.participantsResource.result;
  }

  get count(): number {
    return this.participants.length;
  }

  get isMe(): boolean {
    const myUID = getMyUID();
    return this.participants.filter((p) => p.memberUID === myUID).length > 0;
  }

  beforeMount(): void {
    this.reloadParticipants();
  }

  reloadParticipants(): void {
    Resource.fetchResource(
      `participants-count:${this.eventId}`,
      () => fetchParticipants(this.eventId),
      (res) => {
        this.participantsResource = res;
      }
    );
  }
}
