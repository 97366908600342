






import { Component, Vue, Prop } from "vue-property-decorator";
import { FeedEntry } from "@/domain";
import FeedEntryAny from "./FeedEntryAny.vue";

@Component({
  components: {
    FeedEntryAny,
  },
})
export default class FeedList extends Vue {
  @Prop({ required: true })
  items!: FeedEntry[];
}
