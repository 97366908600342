

















import { Component, Vue } from "vue-property-decorator";
import { Resource, StatefulResource } from "vue-stateful-resource";
import { DeoEvent } from "@/domain";
import { fetchEvent } from "@/services";
import EventCard from "./EventCard.vue";

@Component({
  components: {
    StatefulResource,
    EventCard,
  },
})
export default class EventView extends Vue {
  eventResource = Resource.empty<DeoEvent>();

  beforeMount(): void {
    const id = this.$route.params.id || "";
    this.reloadEvent(id);
  }

  reloadEvent(id: string): void {
    Resource.fetchResource(
      `event:${id}`,
      () => fetchEvent(id),
      (res) => {
        this.eventResource = res;
      }
    );
  }

  get event(): DeoEvent | "" {
    return this.eventResource.result || "";
  }
}
