import { DeoEvent, EventParticipant, FeedEntry } from "./domain";
import { fetchFromApi } from "./util";
import firebase from "firebase/app";

export async function fetchFeed(): Promise<FeedEntry[]> {
  const path = "feed/personal";
  const resp: { entries: FeedEntry[] } = await fetchFromApi(path);
  return resp.entries;
}

export async function fetchEvent(id: string): Promise<DeoEvent> {
  const resp: DeoEvent = await fetchFromApi(`event/${id}/`);
  return resp;
}

export async function fetchParticipants(
  eventID: string
): Promise<EventParticipant[]> {
  const resp: { participants: EventParticipant[] } = await fetchFromApi(
    `event/${eventID}/participants/list`
  );
  return resp.participants;
}

export async function participate(eventID: string): Promise<void> {
  await fetchFromApi(`event/${eventID}/participants/participate`);
}

export async function unparticipate(eventID: string): Promise<void> {
  await fetchFromApi(`event/${eventID}/participants/unparticipate`);
}

export function getMyUID(): string | undefined {
  return firebase.auth().currentUser?.uid;
}
