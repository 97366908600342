import firebase from "firebase/app";
import "firebase/auth";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueRouter from "vue-router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Vue from "vue";
import App from "./App.vue";
import { EventView, FeedView } from "./components";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);
Vue.config.productionTip = false;

function initVue(mountTarget: string) {
  const router = new VueRouter({
    routes: [
      { path: "/", component: FeedView },
      { path: "/event/:id", component: EventView },
    ],
  });
  new Vue({
    render: (h) => h(App),
    router,
  }).$mount(mountTarget);
}

export async function mountDeoetorbiWepapp(
  mountTarget: string
): Promise<void> {
  async function initFirebaseFromJSON(url: string) {
    const response = await fetch(url);
    firebase.initializeApp(await response.json());
  }

  try {
    await initFirebaseFromJSON("/__/firebase/init.json");
  } catch (err) {
    console.warn(
      "Failed to load firebase from firebase hosting, " +
        "trying to load dev config. Error: ",
      err
    );
    await initFirebaseFromJSON("/firebase.init.dev.json");
    console.log("Successfully loaded firebase dev config");
  }
  initVue(mountTarget);
}
